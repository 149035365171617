import "./assets/css/tailwind.css";
import "./assets/css/core.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { LanguageProvider } from "./context/LanguageContext";
import EmpresasComponent from "./components/EmpresasComponent";

export default function Root(props) {
  return (
    <LanguageProvider>
      <I18nextProvider i18n={i18n}>
        <>
          <EmpresasComponent />
        </>
      </I18nextProvider>
    </LanguageProvider>
  );
}
