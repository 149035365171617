import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        index: {
          back: "Voltar",
        },

        hero: {
          title: "Colaboradores do ASSIST 365",
          cta: "Entrar",
        },
        body: {
          title: "Quer se cadastrar para trabalhar conosco?",
          cta1: "Junte-se ao programa de afiliados",
          cta2: "Eu sou uma agência",
        },
      },
    },
    es: {
      translations: {
        index: {
          back: "Volver",
        },

        hero: {
          title: "Colaboradores Assist 365",
          cta: "Ingresar",
        },
        body: {
          title: "¿Quieres darte de alta para trabajar con nosotros?",
          cta1: "Unirme al programa de afiliados",
          cta2: "Soy una agencia",
        },
      },
    },
    "es-AR": {
      translations: {
        index: {
          back: "Volver",
        },

        hero: {
          title: "Colaboradores Assist 365",
          cta: "Ingresar",
        },
        body: {
          title: "¿Querés darte de alta para trabajar con nosotros?",
          cta1: "Unirme al programa de afiliados",
          cta2: "Soy una agencia",
        },
      },
    },
  },
  lng: "es-AR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});
export default i18n;
